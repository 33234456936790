import { UserRole } from 'src/app/entities/user-role';

export interface UrlDto {
  redirectUrl: string;
}

export interface BalanceDto {
  Status: string;
  Account: string;
  Balance: number;
	AvailableToBuy: number;
	Limit?: number;
	PaymentsOnHold?: boolean;
}

export const allowedRoles = [
  UserRole.RestaurantOwner,
  UserRole.RestaurantManager,
  UserRole.AccountManager,
  UserRole.PartnerHead,
];
